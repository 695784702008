import { useCallback } from 'react'
import { useCurrentRefinements } from 'react-instantsearch'

import { Chip, Stack } from '@mui/material'
import type { CurrentRefinementsConnectorParamsRefinement } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'

import { HIERARCHICAL_CATEGORIES } from '@/constants/algolia'
import { useRenderKey } from '@/providers/c/RenderKeyProvider'
import { useSearchBoxWithInternalState } from '@/providers/c/SearchBoxWithInternalStateProvider'
import { useCategoriesAnalyticsTracking } from '@/utils/frontend/analytics/useCategoriesAnalyticsTracking'

const dataCy = 'ActiveFiltersChips'

const ChipItem = ({
  item,
  handleClick,
}: {
  item: CurrentRefinementsConnectorParamsRefinement
  handleClick(item: CurrentRefinementsConnectorParamsRefinement): void
}) => {
  const { clear } = useSearchBoxWithInternalState()
  const { forceNewRenderForFilterChips } = useRenderKey()
  const { trackFilterEvent } = useCategoriesAnalyticsTracking()

  const refinement = {
    label: item.label,
    attribute: item.attribute,
    value: item.value,
    type: item.type,
  }

  const onDelete = () => {
    if (item.attribute === 'query') return clear()
    handleClick(refinement)
    forceNewRenderForFilterChips()
    trackFilterEvent(item.attribute, [String(item.value)])
  }

  return (
    <Chip
      label={item.value}
      variant="outlined"
      onDelete={onDelete}
      data-cy={`${dataCy}-chip-${item.attribute}-${item.value}`}
    />
  )
}

export const ActiveFiltersChips = () => {
  const { items, refine } = useCurrentRefinements({
    excludedAttributes: [
      // this hierarchicalCategories will hide also the type and family selected
      HIERARCHICAL_CATEGORIES[0],
    ],
  })

  const handleClick = useCallback(
    (refinement: CurrentRefinementsConnectorParamsRefinement) => {
      refine(refinement)
    },
    [refine],
  )

  if (items.length === 0) return null

  return (
    <Stack direction="row" sx={{ gap: 1, flexWrap: 'wrap' }}>
      {items.map((item) =>
        item.refinements.map((nested) => (
          <ChipItem
            key={`${nested.attribute}-${nested.value}`}
            item={nested}
            handleClick={handleClick}
          />
        )),
      )}
    </Stack>
  )
}
